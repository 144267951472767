@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url("~/layouts/fonts/Inter-Thin.woff2") format("woff2");
}
@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url("~/layouts/fonts/Inter-ThinItalic.woff2") format("woff2");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url("~/layouts/fonts/Inter-ExtraLight.woff2") format("woff2");
}
@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url("~/layouts/fonts/Inter-ExtraLightItalic.woff2") format("woff2");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("~/layouts/fonts/Inter-Light.woff2") format("woff2");
}
@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url("~/layouts/fonts/Inter-LightItalic.woff2") format("woff2");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("~/layouts/fonts/Inter-Regular.woff2") format("woff2");
}
@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("~/layouts/fonts/Inter-Italic.woff2") format("woff2");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("~/layouts/fonts/Inter-Medium.woff2") format("woff2");
}
@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url("~/layouts/fonts/Inter-MediumItalic.woff2") format("woff2");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("~/layouts/fonts/Inter-SemiBold.woff2") format("woff2");
}
@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url("~/layouts/fonts/Inter-SemiBoldItalic.woff2") format("woff2");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("~/layouts/fonts/Inter-Bold.woff2") format("woff2");
}
@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url("~/layouts/fonts/Inter-BoldItalic.woff2") format("woff2");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url("~/layouts/fonts/Inter-ExtraBold.woff2") format("woff2");
}
@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url("~/layouts/fonts/Inter-ExtraBoldItalic.woff2") format("woff2");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url("~/layouts/fonts/Inter-Black.woff2") format("woff2");
}
@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url("~/layouts/fonts/Inter-BlackItalic.woff2") format("woff2");
}
