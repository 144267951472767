@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url("Inter-Thin.30fa2792.woff2") format("woff2");
}

@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url("Inter-ThinItalic.baaa147a.woff2") format("woff2");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url("Inter-ExtraLight.964a821e.woff2") format("woff2");
}

@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url("Inter-ExtraLightItalic.8892db5f.woff2") format("woff2");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("Inter-Light.c06f5e9d.woff2") format("woff2");
}

@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url("Inter-LightItalic.9361988e.woff2") format("woff2");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("Inter-Regular.449f0e8a.woff2") format("woff2");
}

@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("Inter-Italic.26f3dc3f.woff2") format("woff2");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("Inter-Medium.acc3d50f.woff2") format("woff2");
}

@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url("Inter-MediumItalic.078088d6.woff2") format("woff2");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("Inter-SemiBold.bdb1c7ad.woff2") format("woff2");
}

@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url("Inter-SemiBoldItalic.2550cf4d.woff2") format("woff2");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("Inter-Bold.53ea85ae.woff2") format("woff2");
}

@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url("Inter-BoldItalic.2c92d711.woff2") format("woff2");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url("Inter-ExtraBold.3eeb4074.woff2") format("woff2");
}

@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url("Inter-ExtraBoldItalic.1abc120e.woff2") format("woff2");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url("Inter-Black.1da350e0.woff2") format("woff2");
}

@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url("Inter-BlackItalic.f3c01519.woff2") format("woff2");
}

/*# sourceMappingURL=index.3ce97264.css.map */
